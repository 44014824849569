<template>
  <div class="content-wrapper">
    <h1 class="mb-5">Companies</h1>
    <b-card class="mb-4">
      <b-row class="my-1">
        <b-col lg="3">
          <b-input-group size="sm" class="mb-2">
            <b-input-group-prepend is-text>
              <b-icon icon="search"></b-icon>
            </b-input-group-prepend>
            <b-form-input id="filter-input" v-model="filter" type="search" placeholder="Search"></b-form-input>
          </b-input-group>
        </b-col>
        <b-col class="text-lg-right">
          <b-button size="sm" class="mb-2" v-b-toggle.sidebar-companies>
            <b-icon icon="plus" aria-hidden="true"></b-icon> New company
          </b-button>
        </b-col>
      </b-row>
    </b-card>
    <b-overlay :show="items == null" rounded="sm" class="overlay-placeholder">
      <b-card>
        <b-table hover :items="items" :fields="fields" :current-page="currentPage" :per-page="perPage" :filter="filter">
          <template #cell(actions)="row">
            <b-dropdown size="sm" variant="link" toggle-class="text-decoration-none" no-caret>
              <template #button-content>
                <b-icon-gear-fill></b-icon-gear-fill>
              </template>
              <b-dropdown-item href="#" v-b-toggle.sidebar-companies @click="
  currentCompany = row.item;
tmpCompany = JSON.parse(JSON.stringify(row.item));
              ">Edit</b-dropdown-item>
              <!-- <b-dropdown-item
                href="#"
                v-b-toggle.sidebar-companyKpis
                @click="getCompanyKpi(row.item)"
                >Manage Company KPIs</b-dropdown-item
              > -->
              <b-dropdown-item href="#" v-if="row.item.attiva" @click="updateState(row.item)">Block</b-dropdown-item>
              <b-dropdown-item href="#" @click="updateState(row.item)" v-else>Unlock</b-dropdown-item>

              <b-dropdown-item href="#" @click="
  currentCompany = row.item;
showDeleteModal = true;
              ">Delete</b-dropdown-item>
            </b-dropdown>
          </template>
          <template #cell(attiva)="row">
            <b-icon-unlock-fill v-if="row.item.attiva"></b-icon-unlock-fill>
            <b-icon-lock-fill v-else></b-icon-lock-fill>
          </template>
        </b-table>
        <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="perPage" align="fill" size="sm"
          class="mt-4 mb-1"></b-pagination>
      </b-card>
    </b-overlay>
    <b-sidebar class="right-sidebar" id="sidebar-companies" right shadow lazy @hidden="resetData">
      <div class="content-wrapper my-3 mx-1">
        <h4 v-if="tmpCompany && tmpCompany.ragione_sociale">
          {{ tmpCompany.ragione_sociale }}
        </h4>
        <h4 v-else>New company</h4>
        <b-tabs lazy content-class="mt-3" class="mt-3">
          <b-tab title="Dati generali" active class="mx-3 mt-4">
            <b-form-group label="Ragione Sociale" label-for="company">
              <b-form-input v-model="tmpCompany.ragione_sociale" />
            </b-form-group>
            <b-row>
              <b-col>
                <b-form-group label="P. Iva" label-for="company">
                  <b-form-input v-model="tmpCompany.partita_iva" />
                </b-form-group>
              </b-col>
              <b-col>
                <b-form-group label="Cod. Fiscale" label-for="company">
                  <b-form-input v-model="tmpCompany.codice_fiscale" />
                </b-form-group>
              </b-col>
            </b-row>
            <b-form-group label="Campaign Languages">
              <TagsInput v-model="tmpCompany.lingue" :tagsList="this.languages" text-field="name" value-field="ID" />
            </b-form-group>
            <b-form-group label="Note">
              <b-form-textarea v-model="tmpCompany.note" rows="3" />
            </b-form-group>
            <div class="mt-5 mb-5">
              <b-button squared variant="primary" size="lg" class="big-button" @click="saveItem">Confirm</b-button>
            </div>
          </b-tab>
          <b-tab title="Plugins">
            <span style="color:grey" v-if="listPlugins.length == 0">
              <b-icon icon="exclamation-circle"></b-icon>
              No plugins availables
            </span>
            <b-form-checkbox-group v-else switch v-model="pluginSelection" class="p-2">
              <div class="checkbox-container">
                <b-form-checkbox v-for="item in pluginsOptions" :key="item.value" :value="item.value" class="mb-2 mt-1">
                  <strong> {{ item.text }}</strong>
                </b-form-checkbox>
              </div>
            </b-form-checkbox-group>
            <div class="mt-5 mb-5">
              <b-button squared variant="primary" size="lg" class="big-button" @click="savePlugins">Confirm</b-button>
            </div>
          </b-tab>
        </b-tabs>
      </div>
    </b-sidebar>
    <!-- <b-sidebar class="right-sidebar" id="sidebar-companyKpis" right shadow lazy @hidden="resetData">
      <div class="content-wrapper my-3 mx-1">
        <b-row class="ion-justify-content-between">
          <b-col>
            <h4>Company's KPIs</h4>
          </b-col>
          <b-col cols="auto">
            <b-button @click="downloadKpiModel">DOWNLOAD KPI MODEL</b-button>
          </b-col>
        </b-row>
        <div v-if="tree.length > 0">
          <b-row>
            <b-col md="12" xl="9" class="pt-4">
              <draggable :list="tree" handle=".kpi-handle" ghost-class="ghost-item">
                <div v-for="item in tree" :key="item.id">
                  <div class="nestable-whappy-item d-flex align-items-center item">
                    <span class="ml-2 text-muted kpis-label" style="width: 2.5rem">{{ item.type == 0 ? "ABS" : "INC"
                    }}</span>

                    <span class="ml-2 text-muted kpis-label" style="width: 2rem">
                      <b-icon-file-earmark-excel v-if="item.UpdateMode == 1"></b-icon-file-earmark-excel>
                      <b-icon-grid v-if="item.UpdateMode == 2"></b-icon-grid>
                      <b-icon-code-slash v-if="item.UpdateMode == 3"></b-icon-code-slash>
                    </span>

                    <span class="ml-2"><strong>{{ item.name }}</strong></span>
                    <span class="ml-auto mr-2 kpis-label">{{
                    item.default_goal
                    }}</span>
                    <div class="
                        tools-box
                        align-self-stretch
                        d-flex
                        align-items-center
                      ">
                      <b-icon icon="plus" class="pointer" v-b-toggle.sidebar-kpis @click="tmpKpi.id_padre = item.id">
                      </b-icon>
                      <b-icon icon="gear-fill" class="pointer" v-b-toggle.sidebar-kpis @click="setTmpKpi(item)">
                      </b-icon>
                      <b-icon icon="trash-fill" class="pointer" @click="deleteCheck(item)"></b-icon>
                    </div>
                  </div>
                  <div class="
                      nestable-whappy-item
                      d-flex
                      align-items-center
                      mb-2
                      subItem
                    " v-for="subItem in item.children" :key="subItem.id">
                    <span class="ml-2 text-muted kpis-label" style="width: 2.5rem">{{ subItem.type == 0 ? "ABS" : "INC"
                    }}</span>
                    <span class="ml-2 text-muted kpis-label" style="width: 2rem">
                      <b-icon-people-fill v-if="subItem.view == 'gruppo'" />
                      <b-icon-person-fill v-else />
                    </span>
                    <span class="ml-2"><strong>{{ subItem.name }}</strong></span>
                    <span class="ml-auto mr-2 kpis-label">{{
                    subItem.default_goal
                    }}</span>

                    <div class="
                        tools-box
                        align-self-stretch
                        d-flex
                        align-items-center
                      ">
                      <b-icon icon="plus" style="visibility: hidden"></b-icon>
                      <b-icon icon="gear-fill" class="pointer" v-b-toggle.sidebar-kpis @click="setTmpKpi(subItem)">
                      </b-icon>
                      <b-icon icon="trash-fill" class="pointer" @click="deleteCheck(subItem)"></b-icon>
                    </div>
                  </div>
                </div>
              </draggable>
            </b-col>
          </b-row>
        </div>
        <div v-else>Non è presente ancora nessun KPI</div>
        <b-row class="mt-5">
          <b-col>
            <b-button squared size="lg" class="button-dashed big-button" v-b-toggle.sidebar-kpis>Add new Kpi</b-button>
          </b-col>
        </b-row>
      </div>
    </b-sidebar> -->
    <!-- <b-sidebar class="right-sidebar-subsidebar" id="sidebar-kpis" right shadow lazy @hidden="resetData">
      <div class="content-wrapper my-3 mx-1">
        <h2 v-if="tmpKpi.id">Edit KPI</h2>
        <h2 v-else-if="tmpKpi.id_padre && !tmpKpi.id">Add subset KPI</h2>
        <h2 v-else>Add new KPI</h2>

        <b-form-group class="mt-4">
          <b-form-checkbox v-model="tmpKpi.Disponibile">Available for campaigns</b-form-checkbox>
        </b-form-group>

        <b-form-group label="Name" class="mt-4">
          <b-form-input v-model="tmpKpi.name" />
        </b-form-group>

        <b-form-group label="Update Mode" class="mt-4">
          <b-form-select v-model="tmpKpi.UpdateMode" :options="UpdateMode_options"></b-form-select>
        </b-form-group>

        <b-form-group v-if="tmpKpi.UpdateMode == 3" label="Plugin" class="mt-4">
          <b-row>
            <b-col class="col-6">
              <b-form-select v-model="pluginSelected" :options="pluginsOptions" class="mt-2 pl-4"></b-form-select>
            </b-col>
            <b-col class="col-6">
              <b-form-select v-if="pluginSelected == 1" v-model="productSelected" :options="productsOptions"
                class="mt-2 pl-4"></b-form-select>
            </b-col>
          </b-row>
        </b-form-group>

        <template v-if="!tmpKpi.id_padre">
          <b-form-group label="Type" class="mt-4">
            <b-form-select v-model="tmpKpi.type" :options="type_options"></b-form-select>
          </b-form-group>
        </template>

        <div class="mt-5 mb-5">
          <b-button squared variant="primary" size="lg" class="big-button" @click="saveKpi">Confirm</b-button>
        </div>
      </div>
    </b-sidebar> -->
    <b-modal v-model="showDeleteModal" title="Attention!" ok-title="Confirm" cancel-title="Cancel" @hidden="resetData"
      @ok="deleteItem(currentCompany.id)">Do you really want to delete the selected element?</b-modal>
  </div>
</template>

<script>
import AxiosService from "./../axiosServices/AxiosService";
// import draggable from "vuedraggable";
import { mapGetters } from "vuex";
export default {
  // components: { draggable },
  data() {
    return {
      tree: [],
      listPlugins: [],
      items: null,
      fields: [
        { key: "id", label: "ID" },
        { key: "ragione_sociale", label: "Company" },
        { key: "n_admin", label: "Admin team" },
        { key: "n_users", label: "User" },
        { key: "actions", label: "Actions" },
        { key: "attiva", label: "Active" },
      ],
      currentPage: 1,
      perPage: 10,
      filter: "",
      currentCompany: null,
      tmpCompany: {
        id: 0,
        ragione_sociale: "",
        partita_iva: "",
        codice_fiscale: "",
        telefono: "",
        email: "",
        sito_web: "",
        url_logo: "",
        fatturato: 0,
        utile: 0,
        n_dipendenti: 0,
        n_reparti: 0,
        n_admin: 0,
        n_users: 0,
        note: "",
        social: {},
        lingue: [],
        attiva: false,
      },

      companyKpisList: [],
      tmpKpi: {
        name: "",
        type: null,
        UpdateMode: null,
        Disponibile: true,
        idPlugin: 0,
        idElementPlugin: 0,
      },
      currentKpi: null,

      type_options: [
        { value: null, text: "Select type" },
        { value: 0, text: "Absolute" },
        { value: 1, text: "Incremental" },
      ],
      UpdateMode_options: [
        { value: null, text: "Select update mode" },
        { value: 1, text: "Manual" },
        { value: 2, text: "API" },
        { value: 3, text: "Plugin" },
      ],
      pluginsOptions: [
        { value: null, text: "Select plugin" },
        { value: 1, text: "Sales chat" },
      ],
      pluginSelection: [],

      productsOptions: [{ value: null, text: "Seleziona il Prodotto" }],
      productSelected: null,

      showDeleteModal: false,
    };
  },
  service: null,
  servicePluginsGET: null,
  servicePluginsPUT: null,
  created() {
    this.service = new AxiosService("Company");
    this.servicePlugins = new AxiosService("Plugin/Manage/GetPluginByCompany/");
    this.servicePlugins = new AxiosService("Plugin/Manage/SavePluginByCompany/");

  },
  mounted() {
    this.service.read().then((data) => {
      this.items = data;
    });
    this.servicePluginsGET.read().then(res => this.pluginsOptions = res)
  },
  methods: {
    downloadKpiModel() {
      this.service
        .readCustomEndpoint(
          "KpiManager/CreateKpiModels/" + this.currentCompany.id
        )
        .then((res) => {
          var fileURL = this.$store.getters.base_address + res;

          window.location.assign(fileURL);
        });
    },
    saveKpi() {
      let item = { ...this.tmpKpi };
      item.idPlugin = this.pluginSelected;
      item.idElementPlugin = this.productSelected;
      const validationObject = this.$validate("kpiGlobal", item);
      if (!validationObject.validation) {
        this.$errorToast(validationObject.messages.join(", "));
        return false;
      }

      if (item.id) {
        this.service
          .putCustomEndpoint("GlobalKpi", item.id, item)
          .then((response) => {
            if (response.id == item.id) {
              Object.keys(this.currentKpi).forEach((element) => {
                if (Object.prototype.hasOwnProperty.call(item, element)) {
                  this.currentKpi[element] = item[element];
                }
              });
              this.$root.$emit("bv::toggle::collapse", "sidebar-kpis");
              this.companyKpisList.splice(0);
              this.tree.splice(0);
              this.service
                .readCustomEndpoint(
                  "GlobalKpi?idCompany=" + this.currentCompany.id
                )
                .then((res) => {
                  this.companyKpisList = res;
                  this.tree = this.buildTree(this.companyKpisList);
                });
              this.$successToast();
            } else {
              this.$errorToast();
              return false;
            }
          })
          .catch(() => {
            this.$errorToast();
            return false;
          });
      } else {
        this.service
          .postCustomEndpoint(
            "GlobalKpi?idCompany=" + this.currentCompany.id,
            "",
            item
          )
          .then((response) => {
            if (response.id) {
              item.id = response.id;
              this.companyKpisList.push(item);
              this.$root.$emit("bv::toggle::collapse", "sidebar-kpis");
              this.companyKpisList.splice(0);
              this.tree.splice(0);
              this.service
                .readCustomEndpoint(
                  "GlobalKpi?idCompany=" + this.currentCompany.id
                )
                .then((res) => {
                  this.companyKpisList = res;
                  this.tree = this.buildTree(this.companyKpisList);
                });
              this.$successToast();
            } else {
              this.$errorToast();
              return false;
            }
          })
          .catch(() => {
            this.$errorToast();
            return false;
          });
      }

      this.companyKpisList.splice(0);
      this.service
        .readCustomEndpoint("GlobalKpi?idCompany=" + this.currentCompany.id)
        .then((res) => {
          this.companyKpisList = res;
        });
    },
    deleteKpi(id) {
      this.service
        .deleteCustomEndpoint("GlobalKpi", id)
        .then((response) => {
          if (response) {
            this.companyKpisList = this.companyKpisList.filter(
              (currentItem) => id !== currentItem.id
            );
            this.tree = this.buildTree(this.companyKpisList);
            this.$successToast();
          } else {
            this.$errorToast();
            return false;
          }
        })
        .catch(() => {
          this.$errorToast();
          return false;
        });
    },
    buildTree() {
      let list = JSON.parse(JSON.stringify(this.companyKpisList));
      if (!list) return [];
      let map = {},
        node,
        roots = [],
        i;
      for (i = 0; i < list.length; i += 1) {
        map[list[i].id] = i; // initialize the map
        list[i].children = []; // initialize the children
      }
      for (i = 0; i < list.length; i += 1) {
        node = list[i];
        if (node.id_padre && node.id_padre !== "0") {
          if (Object.prototype.hasOwnProperty.call(map, node.id_padre)) {
            list[map[node.id_padre]].children.push(node);
          }
          //else nodo orfano
        } else {
          roots.push(node);
        }
      }
      return roots;
    },
    deleteCheck(item) {
      if (item.children && item.children.length) {
        this.showDeleteModal = true;
      } else {
        this.deleteKpi(item.id);
      }
    },
    setTmpKpi(item) {
      let index = this.companyKpisList.findIndex((x) => x.id === item.id);
      if (index > -1) {
        this.currentKpi = this.companyKpisList[index];
        this.tmpKpi = JSON.parse(JSON.stringify(this.currentKpi));
        this.pluginSelected = this.companyKpisList[index].idPlugin;
        this.productSelected = this.companyKpisList[index].idElementPlugin;
      }
    },
    getCompanyKpi(item) {
      this.companyKpisList.splice(0);
      this.currentCompany = item;
      this.tmpCompany = JSON.parse(JSON.stringify(item));
      this.service
        .readCustomEndpoint("GlobalKpi?idCompany=" + this.currentCompany.id)
        .then((res) => {
          this.companyKpisList = res;
          this.tree = this.buildTree(this.companyKpisList);
        });
      this.service.readCustomEndpoint("SalesChat/GetProducts").then((res) => {
        res.forEach((element) => {
          element.value = element.id;
          element.text = element.nome;
          this.productsOptions.push(element);
        });
      });
    },
    resetData() {
      // this.currentCompany = null;
      this.tmpCompany = {
        id: 0,
        ragione_sociale: "",
        partita_iva: "",
        codice_fiscale: "",
        telefono: "",
        email: "",
        sito_web: "",
        url_logo: "",
        fatturato: 0,
        utile: 0,
        n_dipendenti: 0,
        n_reparti: 0,
        n_admin: 0,
        n_users: 0,
        note: "",
        social: {},
        lingue: [],
        attiva: false,
      };
      this.tmpCompanyLanguages = [];
      this.pluginsOptions.splice(1);
      this.currentKpi = null;
      this.tmpKpi = {
        id: 0,
        id_padre: 0,
        name: "",
        type: 0,
        UpdateMode: 0,
        Disponibile: true,
        idPlugin: 0,
        idElementPlugin: 0,
      };
    },
    saveItem() {
      let item = { ...this.tmpCompany };

      const validationObject = this.$validate("company", item);
      if (!validationObject.validation) {
        this.$errorToast(validationObject.messages.join(", "));
        return false;
      }

      if (item.id) {
        this.service
          .update(item)
          .then((response) => {
            if (response.id == item.id) {
              Object.keys(this.currentCompany).forEach((element) => {
                if (Object.prototype.hasOwnProperty.call(item, element)) {
                  this.currentCompany[element] = item[element];
                }
              });
              this.$root.$emit("bv::toggle::collapse", "sidebar-companies");
              this.$successToast();
            } else {
              this.$errorToast();
              return false;
            }
          })
          .catch(() => {
            this.$errorToast();
            return false;
          });
      } else {
        this.service
          .create(item)
          .then((response) => {
            if (response.id) {
              item.id = response.id;
              this.items.push(item);
              this.$root.$emit("bv::toggle::collapse", "sidebar-companies");
              this.$successToast();
            } else {
              this.$errorToast();
              return false;
            }
          })
          .catch(() => {
            this.$errorToast();
            return false;
          });
      }
    },
    deleteItem(id) {
      this.service
        .delete(id)
        .then((response) => {
          if (response) {
            this.items = this.items.filter(
              (currentItem) => id !== currentItem.id
            );
            this.$successToast();
          } else {
            this.$errorToast();
            return false;
          }
        })
        .catch(() => {
          this.$errorToast();
          return false;
        });
    },
    updateState(item) {
      item.attiva = !item.attiva;
      this.service.update(item).then((response) => {
        if (response.id == item.id) {
          this.$successToast();
        } else {
          this.$errorToast();
          item.attiva = !item.attiva;
          return false;
        }
      });
    },
    savePlugins() {
      const idCompany = this.tmpCompany.id
      console.log("🚀 ~ file: Companies.vue ~ line 627 ~ savePlugins ~ idCompany", idCompany)

      this.servicePluginsPOST.update(this.pluginSelection)
        .then(res => {
          console.log(res)
        })
    }
  },
  computed: {
    totalRows() {
      return this.items ? this.items.length : 0;
    },
    ...mapGetters(["languages", "default_lang", "config"]),
    //     ...mapGetters(["default_lang"]),
    //     ...mapGetters(["config"]),
  },
  watch: {
    filter: {
      handler() {
        this.currentPage = 1;
      },
    },
  },
};
</script>